<script lang="ts">
  import SubmitButton from "../interactive/SubmitButton.svelte";
  import SearchResetButton from "../interactive/SearchResetButton.svelte";
  import AbortButton from "../interactive/AbortButton.svelte";
  import SearchField from "../interactive/SearchField.svelte";
  import {searchfieldStore, isSuggestOpen} from "../../store/SearchfieldStore";
  import {activeIndex} from "../../store/IndexStore";
  import {SUBMIT_TYPE, submitStore} from "../../store/SubmitStore";
  import {SqrlTestId} from "../../constants/squirrel";
  import {allowSubmit} from "../../store/Derived";
  import type {Unsubscriber} from "svelte/store";
  import {SQRL_SUGGESTION_TYPE} from "../../constants/squirrel";
  import {original} from "../../store/SuggestionStore";

  interface Props {
    sendRequestCallback: (term: string, cursorPosition: number)=> void;
  }

  let { sendRequestCallback }: Props = $props();

  const {value, target} = searchfieldStore;
  let unsubscribe: Unsubscriber;


  /**
 *
 *
 *
 *
 *
 *
 *
 */

  function handleSubmit(event: Event) {
    event.preventDefault();
    if ($value.trim() === "") {
      return;
    }
    if ($activeIndex > -1) {
      const suggestItem: HTMLElement = document.querySelector(`[data-pos="${$activeIndex}"]`) as HTMLElement;
      suggestItem.click();
    } else {
      unsubscribe = allowSubmit.subscribe((allowSubmit) => {
        if (allowSubmit) {
          submitStore.handleSubmit({term: $value}, $target, SUBMIT_TYPE.ENTER, $original.type as SQRL_SUGGESTION_TYPE?? SQRL_SUGGESTION_TYPE.SEARCH);
        }
        /*                     */
        if(unsubscribe) {
          unsubscribe();
        }
      });
    }
  }
</script>

<form class="js_squirrel_searchForm squirrel_searchForm"
      data-article-number-search="/p/search/"
      data-testId={SqrlTestId.SEARCH_LAYER}
      class:squirrel_active={$isSuggestOpen}
      onsubmit={handleSubmit}
      role="search">
    <div class="squirrel_searchline">
        <div class="squirrel_searchbar js_squirrel_searchbar">
            <SearchField sendRequestCallback={sendRequestCallback}/>
            <SearchResetButton/>
            <SubmitButton/>
        </div>
        <AbortButton/>
    </div>
</form>

<style lang="scss">
  @use "~@otto-ec/design-tokens/tokens";
  @use "~@otto-ec/otto-components-utils/scss/_mixins/breakpoint";

  .squirrel_active {
    @include breakpoint.breakpoint-from-l($to: false) {
      border-radius: tokens.$oc-semantic-border-radius-100 tokens.$oc-semantic-border-radius-100 tokens.$oc-semantic-border-radius-0 tokens.$oc-semantic-border-radius-0;
      /*                */
      /*                                            */
      border-color: #c4c4c4;
    }
  }
</style>